@tailwind base; 
@tailwind components; 
@tailwind utilities; 

.modal-overlay-class {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-class {
    position: relative;
    top: 1%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  